import Questions from "../views/modules/Questions/";
import Transplantation from "../views/modules/Transplantation/";
import MedicalNews from "../views/modules/MedicalNews/";
import NewsDetails from "../views/modules/MedicalNews/NewsDetails";
import KidneyTransplant from "../views/modules/KidneyTransplant/";
import MedicalGuide from "../views/modules/MedicalGuide/";
import CentreMedical from "../views/modules/CentreMedical/";
import CentreMedic from "../views/modules/CentreMedic/";
import Association from "../views/modules/Association/";
import Assoc from "../views/modules/Assoc/";
import About from "../views/modules/About/";
import BigFamily from "../views/modules/BigFamily/";

export const AppRouter = [
    {
        path: "/Faq_Kidney",
        element: <Questions />
    },
    {
        path: "/Transplantation",
        element: <Transplantation />
    },
    {
        path: "/MedicalNews",
        element: <MedicalNews />
    },
    {
        path: "/NewsDetails",
        element: <NewsDetails />
    },
    {
        path: "/KidneyTransplant",
        element: <KidneyTransplant />
    },
    {
        path: "/MedicalGuide",
        element: <MedicalGuide />
    },
    {
        path: "/CentreMedic",
        element: <CentreMedical />
    },
    {
        path: "/CentreMedical",
        element: <CentreMedic />
    },
    {
        path: "/Assoc",
        element: <Association />
    },
    {
        path: "/Association",
        element: <Assoc />
    },
    {
        path: "/About-us",
        element: <About />
    },
    {
        path: "/BigFamily",
        element: <BigFamily />
    }

]