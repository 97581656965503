import React, { useEffect } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import Partner1 from '../../../assets/images/login/smn.png'
import Partner2 from '../../../assets/images/login/CHU.png'
import Partner3 from '../../../assets/images/login/USMBAF.png'

import Founder1 from '../../../assets/images/login/loGo-Errahma-Png.png'
import Founder2 from '../../../assets/images/login/ISSAD.png'
import Founder3 from '../../../assets/images/login/Logo-Initiatives-2.png'
import { DownloadCloud } from 'react-feather'
import '../../../assets/css/about.css'
import { Link } from 'react-router-dom'


export default function Index() {

    useEffect(() => {
        document.title = 'عن المنصة | المنصة الرقمية لأمراض الكلى';
    }, [])

    return (
        <div dir='rtl' className='container container-fluid-content'>
            <Row className='d-flex justify-content-center'>

                <Col md="12">

                    <h3 className='fw-bolder'>
                        مرحبًا بكم في المنصة الرقمية لأمراض الكلى
                    </h3>

                    <div className="text-bar-2 mt-3">
                        <p className='pe-5 m-0'>"كليتي" هي أول منصة رقمية تفاعلية مغربية متخصصة في مجال أمراض الكلى.</p>
                        <p className='pe-5 m-0'>تأسست المنصة الرقمية لأمراض الكلى كمبادرة رقمية تفاعلية بهدف توفير بيئة متكاملة ومحدثة تعزز التوعية والرعاية لمرضى الكلى وتعمل على تحسين جودة حياتهم.</p>
                        <h4 className='fw-bolder mt-2'> لماذا المنصة الرقمية لأمراض الكلى؟</h4>
                        <p className='pe-5 m-0'>- يعاني الملايين من أمراض الكلى حول العالم، ونؤمن أنّ المعرفة هي مفتاح الوقاية والعلاج.</p>
                        <p className='pe-5 m-0'> - تفتقر العديد من المجتمعات إلى المعلومات والخدمات الموثوقة حول أمراض الكلى.</p>
                        <p className='pe-5 m-0'>- خلق مجتمع تفاعلي لمشاركة الخبرات والتعلم.</p>
                        <p className='pe-5 m-0'>- المساهمة في تحسين صحة مرضى الكلى وجودة حياتهم.</p>
                    </div>
                </Col>

                <div className='d-flex justify-content-start mt-3'>
                    <Link to="https://kiliati.com/logo.pdf" target='blank'>
                        <Button className='py-3 py-2 ms-2'>
                            <DownloadCloud className='ms-2' />
                            شعار المنصة
                        </Button>
                    </Link>
                    <Link to="https://kiliati.com/kiliati.pdf" target='blank'>
                        <Button className='py-3' >
                            <DownloadCloud className='ms-2' />
                            الملف التعريفي للمنصة
                        </Button>
                    </Link>
                </div>
            </Row >
            <h3 className='fw-bolder text-decoration-underline mt-2'>المؤسسون</h3>
            <Row className='my-5 d-flex justify-content-start align-items-end'>
                <Col md={2} className=' d-flex justify-content-center'>
                    <img src={Founder1} height="auto" width="100%" alt="" />
                </Col>
                <Col md={2} className=' d-flex justify-content-center'>
                    <img src={Founder2} height="auto" width="62%" alt="" />
                </Col>
                <Col md={2} className=' d-flex justify-content-center'>
                    <img src={Founder3} height="auto" width="62%" alt="" />
                </Col>

            </Row>
            <h3 className='fw-bolder text-decoration-underline mt-2'>الشركاء</h3>
            <Row className='my-5 d-flex align-items-center'>
                <Col md={3}>
                    <img src={Partner3} height="auto" width="100%" alt="" />
                </Col>
                <Col md={3}>
                    <img src={Partner1} height="auto" width="100%" alt="" />
                </Col>
                <Col md={3}>
                    <img src={Partner2} height="auto" width="100%" alt="" />
                </Col>
            </Row >

        </div >
    )
}
