import { Fragment, useEffect, useState } from "react";
import { Row, Col, Card, CardBody, CardHeader, Collapse, Button } from "reactstrap";
import url from '../../../Config';
import axios from 'axios';
import { toast } from 'react-toastify';
import { DangerToast } from '../../../toast';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import '../../../assets/css/Question.css'
import { ChevronDown, ChevronUp } from "react-feather";

const Questionss = () => {
    const [data, setData] = useState([])

    const [isCollaps, setIsCollaps] = useState('');

    useEffect(() => {
        axios.get(`${url}api/maladieReins`)
            .then(response => {
                if (response.data.status === 200) {
                    setData(response.data.data)
                } else {
                    toast.error('Danger Notification !', {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 500
                    })
                }
            }).catch(err => toast.error(<DangerToast title={'Erreur de communication avec le serveur'} />, { hideProgressBar: true })
            )
    }, [])

    return data.length > 0 ? (
        <Fragment>
            <Col lg="12" className="header-faq container-fluid-content">
                {/* <HeaderCard title={"الأسئلة الشائعة حول أمراض الكلى"} /> */}
                <Row className="row default-according style-1 faq-accordion" dir="rtl" id="accordionoc">
                    {data.map(item => {
                        return <Col xl="6" lg="6" md="6" sm="12" className="box-col-8 mb-2">
                            <Card>
                                <CardHeader>
                                    <h5 className="mb-0">
                                        <Button color="btn btn-link w-100 d-flex justify-content-between fw-bolder collapsed ps-0 text-dark text-truncate" onClick={() => {
                                            setIsCollaps(item.id === isCollaps ? '' : item.id)
                                        }} aria-expanded={item.id === isCollaps} aria-controls="collapseicon">
                                            {item.question}
                                            {item.id !== isCollaps ? <ChevronDown /> : <ChevronUp />}
                                        </Button>
                                    </h5>
                                </CardHeader>
                                <Collapse isOpen={item.id === isCollaps}>
                                    <CardBody className="text-dark">
                                        <div className="text-dark" dangerouslySetInnerHTML={{ __html: item.answer }} ></div>
                                    </CardBody>
                                </Collapse>
                            </Card>
                        </Col>
                    })}
                </Row>
            </Col>
        </Fragment>
    ) : <Fragment>
        <Col lg="12" className="header-faq">
            <Row className="row default-according style-1 faq-accordion" dir="rtl" id="accordionoc">
                <Col xl="6" lg="6" md="6" sm="12" className="box-col-8 mb-2">
                    <Skeleton height={'50px'} />
                </Col>
                <Col xl="6" lg="6" md="6" sm="12" className="box-col-8 mb-2">
                    <Skeleton height={'50px'} />
                </Col>
                <Col xl="6" lg="6" md="6" sm="12" className="box-col-8 mb-2">
                    <Skeleton height={'50px'} />
                </Col>
                <Col xl="6" lg="6" md="6" sm="12" className="box-col-8 mb-2">
                    <Skeleton height={'50px'} />
                </Col>
                <Col xl="6" lg="6" md="6" sm="12" className="box-col-8 mb-2">
                    <Skeleton height={'50px'} />
                </Col>
                <Col xl="6" lg="6" md="6" sm="12" className="box-col-8 mb-2">
                    <Skeleton height={'50px'} />
                </Col>
                <Col xl="6" lg="6" md="6" sm="12" className="box-col-8 mb-2">
                    <Skeleton height={'50px'} />
                </Col>
                <Col xl="6" lg="6" md="6" sm="12" className="box-col-8 mb-2">
                    <Skeleton height={'50px'} />
                </Col>
                <Col xl="6" lg="6" md="6" sm="12" className="box-col-8 mb-2">
                    <Skeleton height={'50px'} />
                </Col>
                <Col xl="6" lg="6" md="6" sm="12" className="box-col-8 mb-2">
                    <Skeleton height={'50px'} />
                </Col>
                <Col xl="6" lg="6" md="6" sm="12" className="box-col-8 mb-2">
                    <Skeleton height={'50px'} />
                </Col>
                <Col xl="6" lg="6" md="6" sm="12" className="box-col-8 mb-2">
                    <Skeleton height={'50px'} />
                </Col>
                <Col xl="6" lg="6" md="6" sm="12" className="box-col-8 mb-2">
                    <Skeleton height={'50px'} />
                </Col>
                <Col xl="6" lg="6" md="6" sm="12" className="box-col-8 mb-2">
                    <Skeleton height={'50px'} />
                </Col>
                <Col xl="6" lg="6" md="6" sm="12" className="box-col-8 mb-2">
                    <Skeleton height={'50px'} />
                </Col>
                <Col xl="6" lg="6" md="6" sm="12" className="box-col-8 mb-2">
                    <Skeleton height={'50px'} />
                </Col>
                <Col xl="6" lg="6" md="6" sm="12" className="box-col-8 mb-2">
                    <Skeleton height={'50px'} />
                </Col>
                <Col xl="6" lg="6" md="6" sm="12" className="box-col-8 mb-2">
                    <Skeleton height={'50px'} />
                </Col>
                <Col xl="6" lg="6" md="6" sm="12" className="box-col-8 mb-2">
                    <Skeleton height={'50px'} />
                </Col>
                <Col xl="6" lg="6" md="6" sm="12" className="box-col-8 mb-2">
                    <Skeleton height={'50px'} />
                </Col>
                <Col xl="6" lg="6" md="6" sm="12" className="box-col-8 mb-2">
                    <Skeleton height={'50px'} />
                </Col>
                <Col xl="6" lg="6" md="6" sm="12" className="box-col-8 mb-2">
                    <Skeleton height={'50px'} />
                </Col>
                <Col xl="6" lg="6" md="6" sm="12" className="box-col-8 mb-2">
                    <Skeleton height={'50px'} />
                </Col>
                <Col xl="6" lg="6" md="6" sm="12" className="box-col-8 mb-2">
                    <Skeleton height={'50px'} />
                </Col>
            </Row>
        </Col>
    </Fragment>
};
export default Questionss;
