import React, { Fragment } from 'react'
import { Col,  Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const Dripicons = () => {
  return (
    <Fragment>
        <Row>
        <Col sm='12'>
                     <div className="iq-card">
                        <div className="iq-card-header d-flex justify-content-between">
                           <div className="iq-header-title">
                              <h4 className="card-title">Dripicons Icon</h4>
                           </div>
                        </div>
                        <div className="iq-card-body">
                           <p>Use Class <code>&lt;div data-icon="a" class="icon"&gt;&lt;/div&gt;</code></p>
                           <Row className="mt-3">
                              <Col sm='6' md='4' lg='3' xl='2'>
                                 <Link className="iq-icons-list" to="#" >
                                    <div data-icon="a" className="icon"></div>
                                    <span>4k Fill</span>
                                 </Link>
                              </Col>
                              <Col sm='6' md='4' lg='3' xl='2'>
                                 <Link className="iq-icons-list" to="#" >
                                    <div data-icon="o" className="icon"></div>
                                    <span>Up Arrow</span>
                                 </Link>
                              </Col>
                              <Col sm='6' md='4' lg='3' xl='2'>
                                 <Link className="iq-icons-list" to="#" >
                                    <div data-icon="h" className="icon"></div>
                                    <span>Down Arrow</span>
                                 </Link>
                              </Col>
                              <Col sm='6' md='4' lg='3' xl='2'>
                                 <Link className="iq-icons-list" to="#" >
                                    <div data-icon="j" className="icon"></div>
                                    <span>Left Arrow</span>
                                 </Link>
                              </Col>
                              <Col sm='6' md='4' lg='3' xl='2'>
                                 <Link className="iq-icons-list" to="#" >
                                    <div data-icon="i" className="icon"></div>
                                    <span>Right Arrow</span>
                                 </Link>
                              </Col>
                              <Col sm='6' md='4' lg='3' xl='2'>
                                 <Link className="iq-icons-list" to="#" >
                                    <div data-icon="l" className="icon"></div>
                                    <span>left</span>
                                 </Link>
                              </Col>
                              <Col sm='6' md='4' lg='3' xl='2'>
                                 <Link className="iq-icons-list" to="#" >
                                    <div data-icon="k" className="icon"></div>
                                    <span>Down</span>
                                 </Link>
                              </Col>
                              <Col sm='6' md='4' lg='3' xl='2'>
                                 <Link className="iq-icons-list" to="#" >
                                    <div data-icon="n" className="icon"></div>
                                    <span>Up</span>
                                 </Link>
                              </Col>
                              <Col sm='6' md='4' lg='3' xl='2'>
                                 <Link className="iq-icons-list" to="#" >
                                    <div data-icon="m" className="icon"></div>
                                    <span>Right</span>
                                 </Link>
                              </Col>
                              <Col sm='6' md='4' lg='3' xl='2'>
                                 <Link className="iq-icons-list" to="#" >
                                    <div data-icon="f" className="icon"></div>
                                    <span>Anchor</span>
                                 </Link>
                              </Col>
                              <Col sm='6' md='4' lg='3' xl='2'>
                                 <Link className="iq-icons-list" to="#" >
                                    <div data-icon="g" className="icon"></div>
                                    <span>File</span>
                                 </Link>
                              </Col>
                              <Col sm='6' md='4' lg='3' xl='2'>
                                 <Link className="iq-icons-list" to="#" >
                                    <div data-icon="p" className="icon"></div>
                                    <span>Note</span>
                                 </Link>
                              </Col>
                              <Col sm='6' md='4' lg='3' xl='2'>
                                 <Link className="iq-icons-list" to="#" >
                                    <div data-icon="s" className="icon"></div>
                                    <span>Dribbble</span>
                                 </Link>
                              </Col>
                              <Col sm='6' md='4' lg='3' xl='2'>
                                 <Link className="iq-icons-list" to="#" >
                                    <div data-icon="K" className="icon"></div>
                                    <span>Download</span>
                                 </Link>
                              </Col>
                              <Col sm='6' md='4' lg='3' xl='2'>
                                 <Link className="iq-icons-list" to="#" >
                                    <div data-icon="G" className="icon"></div>
                                    <span>Sun</span>
                                 </Link>
                              </Col>
                              <Col sm='6' md='4' lg='3' xl='2'>
                                 <Link className="iq-icons-list" to="#" >
                                    <div data-icon="H" className="icon"></div>
                                    <span>Haf light</span>
                                 </Link>
                              </Col>
                              <Col sm='6' md='4' lg='3' xl='2'>
                                 <Link className="iq-icons-list" to="#" >
                                    <div data-icon="F" className="icon"></div>
                                    <span>Light</span>
                                 </Link>
                              </Col>
                              <Col sm='6' md='4' lg='3' xl='2'>
                                 <Link className="iq-icons-list" to="#" >
                                    <div data-icon="I" className="icon"></div>
                                    <span>Voice</span>
                                 </Link>
                              </Col>
                              <Col sm='6' md='4' lg='3' xl='2'>
                                 <Link className="iq-icons-list" to="#" >
                                    <div data-icon="x" className="icon"></div>
                                    <span>Notification</span>
                                 </Link>
                              </Col>
                              <Col sm='6' md='4' lg='3' xl='2'>
                                 <Link className="iq-icons-list" to="#" >
                                    <div data-icon="J" className="icon"></div>
                                    <span></span>Page
                                 </Link>
                              </Col>
                              <Col sm='6' md='4' lg='3' xl='2'>
                                 <Link className="iq-icons-list" to="#" >
                                    <div data-icon="t" className="icon"></div>
                                    <span>Battery Empty</span>
                                 </Link>
                              </Col>
                              <Col sm='6' md='4' lg='3' xl='2'>
                                 <Link className="iq-icons-list" to="#" >
                                    <div data-icon="u" className="icon"></div>
                                    <span>Battery Full</span>
                                 </Link>
                              </Col>
                              <Col sm='6' md='4' lg='3' xl='2'>
                                 <Link className="iq-icons-list" to="#" >
                                    <div data-icon="v" className="icon"></div>
                                    <span>Battery One</span>
                                 </Link>
                              </Col>
                              <Col sm='6' md='4' lg='3' xl='2'>
                                 <Link className="iq-icons-list" to="#" >
                                    <div data-icon="w" className="icon"></div>
                                    <span>Battery Two</span>
                                 </Link>
                              </Col>
                              <Col sm='6' md='4' lg='3' xl='2'>
                                 <Link className="iq-icons-list" to="#" >
                                    <div data-icon="Q" className="icon"></div>
                                    <span>Cart</span>
                                 </Link>
                              </Col>
                              <Col sm='6' md='4' lg='3' xl='2'>
                                 <Link className="iq-icons-list" to="#" >
                                    <div data-icon="P" className="icon"></div>
                                    <span>Card</span>
                                 </Link>
                              </Col>
                              <Col sm='6' md='4' lg='3' xl='2'>
                                 <Link className="iq-icons-list" to="#" >
                                    <div data-icon="O" className="icon"></div>
                                    <span>Camera</span>
                                 </Link>
                              </Col>
                              <Col sm='6' md='4' lg='3' xl='2'>
                                 <Link className="iq-icons-list" to="#" >
                                    <div data-icon="N" className="icon"></div>
                                    <span>Video</span>
                                 </Link>
                              </Col>
                              <Col sm='6' md='4' lg='3' xl='2'>
                                 <Link className="iq-icons-list" to="#" >
                                    <div data-icon="S" className="icon"></div>
                                    <span>Check</span>
                                 </Link>
                              </Col>
                              <Col sm='6' md='4' lg='3' xl='2'>
                                 <Link className="iq-icons-list" to="#" >
                                    <div data-icon="T" className="icon"></div>
                                    <span>Angle Down</span>
                                 </Link>
                              </Col>
                              <Col sm='6' md='4' lg='3' xl='2'>
                                 <Link className="iq-icons-list" to="#" >
                                    <div data-icon="U" className="icon"></div>
                                    <span>Angle Left</span>
                                 </Link>
                              </Col>
                              <Col sm='6' md='4' lg='3' xl='2'>
                                 <Link className="iq-icons-list" to="#" >
                                    <div data-icon="V" className="icon"></div>
                                    <span>Angle Right</span>
                                 </Link>
                              </Col>
                              <Col sm='6' md='4' lg='3' xl='2'>
                                 <Link className="iq-icons-list" to="#" >
                                    <div data-icon="W" className="icon"></div>
                                    <span>Angle Up</span>
                                 </Link>
                              </Col>
                              <Col sm='6' md='4' lg='3' xl='2'>
                                 <Link className="iq-icons-list" to="#" >
                                    <div data-icon="X" className="icon"></div>
                                    <span>Up Arrow</span>
                                 </Link>
                              </Col>
                              <Col sm='6' md='4' lg='3' xl='2'>
                                 <Link className="iq-icons-list" to="#" >
                                    <div data-icon="Y" className="icon"></div>
                                    <span>Clock</span>
                                 </Link>
                              </Col>
                              <Col sm='6' md='4' lg='3' xl='2'>
                                 <Link className="iq-icons-list" to="#" >
                                    <div data-icon="Z" className="icon"></div>
                                    <span>Reload</span>
                                 </Link>
                              </Col>
                              <Col sm='6' md='4' lg='3' xl='2'>
                                 <Link className="iq-icons-list" to="#" >
                                    <div data-icon="0" className="icon"></div>
                                    <span>Cloud</span>
                                 </Link>
                              </Col>
                              <Col sm='6' md='4' lg='3' xl='2'>
                                 <Link className="iq-icons-list" to="#" >
                                    <div data-icon="1" className="icon"></div>
                                    <span>Cloud Download</span>
                                 </Link>
                              </Col>
                              <Col sm='6' md='4' lg='3' xl='2'>
                                 <Link className="iq-icons-list" to="#" >
                                    <div data-icon="2" className="icon"></div>
                                    <span>Cloud Upload</span>
                                 </Link>
                              </Col>
                              <Col sm='6' md='4' lg='3' xl='2'>
                                 <Link className="iq-icons-list" to="#" >
                                    <div data-icon="5" className="icon"></div>
                                    <span>Arrow</span>
                                 </Link>
                              </Col>
                              <Col sm='6' md='4' lg='3' xl='2'>
                                 <Link className="iq-icons-list" to="#" >
                                    <div data-icon="5" className="icon"></div>
                                    <span>Arrow</span>
                                 </Link>
                              </Col>
                              <Col sm='6' md='4' lg='3' xl='2'>
                                 <Link className="iq-icons-list" to="#" >
                                    <div data-icon="5" className="icon"></div>
                                    <span>Arrow</span>
                                 </Link>
                              </Col>
                              <Col sm='12' className="text-center mt-3">
                                 <Link className="btn btn-primary" to="http://demo.amitjakhu.com/dripicons/" target="_blank">View All Icon</Link>
                              </Col>
                           </Row>
                        </div>
                     </div>
                  </Col>
        </Row>
    </Fragment>
  )
}

export default Dripicons
