import React, { Fragment } from 'react';
import { Card, TabContent, TabPane } from 'reactstrap';
import ListOfTask from './ListTask';

const TabClass = ({ activeTab, data, cat, citys }) => {
    return (
        <Fragment>
            <div className="email-right-aside bookmark-tabcontent">
                <Card className="">
                    <div className="pl-0">
                        <TabContent activeTab={activeTab}>
                            {cat.map((item, index) => {
                                return <TabPane key={index} tabId={item.salt}>
                                    <ListOfTask cat={item} citys={citys}
                                        allData={data.filter(col => col.categId === item.salt)} />
                                </TabPane>
                            })}
                        </TabContent>
                    </div>
                </Card>
            </div>
        </Fragment>
    );
};

export default TabClass;