import React, { Fragment, useState } from 'react';
import { Facebook, Instagram, Linkedin, MapPin, Twitter, X } from 'react-feather';
import { CardBody, Table, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { Link } from 'react-router-dom';
import url from '../../../Config';
import '../../../assets/css/imageCentre.css'

const CreatedByme = ({ allData }) => {
    const [modalLogo, setModalLogo] = useState(false)
    const [item, setItem] = useState('')

    return (
        <Fragment>
            <CardBody className="p-0">
                <div className="taskadd">
                    <div className="table-responsive table-borderless ">
                        <Table striped>
                            <thead>
                                <tr>
                                </tr>
                            </thead>
                            <tbody>
                                {allData.map((item, index) => {
                                    return <tr key={index}>
                                        <td>
                                            <div className="avatar" onClick={() => {
                                                setModalLogo(true)
                                                setItem(item.salt)
                                            }}>
                                                <img body={true} className={'imgCenterMedic rounded-circle'} src={`${url}api/centreMedicalImage/${item.salt}`} role='button' alt='#' />
                                            </div>
                                        </td>
                                        <td>
                                            <h6 attrh6={{ className: 'task_title_0' }} >{item.nom}</h6>
                                            <p attrPara={{ className: 'project_name_0 ', style: { direction: 'ltr' } }} >{item.phone}</p>
                                        </td>
                                        <td>
                                            <h6 attrh6={{ className: 'task_title_0' }} >{item.email}</h6>
                                            {item.facebook !== '' ? <Link to={item.facebook} className='me-50' target='_blank'><Facebook color='#0866ff' /></Link> : ''}
                                            {item.twitter !== '' ? <Link to={item.twitter} className='me-1' target='_blank'><Twitter color='#1d9bf0' /></Link> : ''}
                                            {item.instagram !== '' ? <Link to={item.instagram} className='me-1' target='_blank'><Instagram color='#fc0b7e' /></Link> : ''}
                                            {item.linkidIn !== '' ? <Link to={item.linkidIn} className='me-1' target='_blank'><Linkedin color='#0a66c2' /></Link> : ''}
                                        </td>
                                        <td>
                                            <p attrPara={{ className: 'task_desc_0' }} >{item.ville !== '' ? <span className='fw-bolder'>{item.ville} - </span> : ""} {item.adresse}</p>
                                        </td>

                                        <td>
                                            {item.mapLink !== '' ? <a href={item.mapLink} rel='noreferrer' target='_blank'><MapPin color='#5c81b9' /></a> : ''}
                                        </td>
                                    </tr>
                                })}
                            </tbody>

                        </Table>
                    </div>
                </div>
            </CardBody>
            <Modal isOpen={modalLogo} toggle={() => setModalLogo(false)} className='text-light' size='md' centered>
                <ModalHeader className='d-flex justify-content-end border-0 '>
                    <X size={24} className='fw-bolder' onClick={() => setModalLogo(false)} role='button' />
                </ModalHeader>
                <ModalBody className='bg-white text-center bg-transparent'>
                    <img src={`${url}api/centreMedicalImage/${item}`} width={'100%'} alt='' />
                </ModalBody>
            </Modal>
        </Fragment >
    );
};

export default CreatedByme;