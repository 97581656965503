import React, { Fragment, memo, useEffect, useState } from "react";

// react-router
import { Link } from "react-router-dom";

// react-bootstrap
import { Navbar, Dropdown } from "react-bootstrap";

// components
import CustomToggle from "../../dropdowns";

import user1 from "../../../assets/images/renal/LogoKiliati.png";

import { useSelector } from "react-redux";
import * as SettingSelector from "../../../store/setting/selectors";
import { Home, Info } from "react-feather";

const Header = memo(() => {
  // Fixed Header
  const [isFixed, setIsFixed] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= 75) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const appName = useSelector(SettingSelector.app_name);

  return (
    <Fragment>
      <div className={`iq-top-navbar ${isFixed ? "fixed-header" : ""}`}>
        <div className="iq-navbar-custom ">
          <div className="iq-sidebar-logo">
            <div className="top-logo">
              <Link to="/" className="logo">
                <img src="images/logo.png" className="img-fluid" alt="" />
                <span>{appName}</span>
              </Link>
            </div>
          </div>
          <Navbar expand="lg" variant="light" className="p-0 d-flex justify-content-end">
            <div className="iq-search-bar">
              <ul className="navbar-nav ml-auto navbar-list">
                {/* <Dropdown as="li" className="nav-item">
                  <Dropdown.Toggle
                    as={CustomToggle}

                    className="search-toggle iq-waves-effect"
                  >

                    <span className="d-none d-md-inline"> اتصل بنا </span>
                    <Mail className="d-inline d-md-none" />
                  </Dropdown.Toggle>
                </Dropdown> */}
                <Dropdown as="li" className="nav-item">
                  <Dropdown.Toggle
                    as={CustomToggle}

                    className="search-toggle iq-waves-effect"
                  >
                    <Link to={'/About-us'}>
                      <span className="d-none d-md-inline"> عن المنصة </span>
                      <Info className="d-inline d-md-none" />
                    </Link>
                  </Dropdown.Toggle>
                </Dropdown>
                <Dropdown as="li" className="nav-item">
                  <Dropdown.Toggle
                    as={CustomToggle}

                    className="search-toggle iq-waves-effect"
                  >
                    <Link to={'/'}>
                      <span className="d-none d-md-inline"> الرئيسية </span>
                      <Home className="d-inline d-md-none" />
                    </Link>
                  </Dropdown.Toggle>
                </Dropdown>
              </ul>
            </div>

            <ul className="navbar-list">
              <Dropdown as="li">
                <Dropdown.Toggle

                  as={CustomToggle}
                  variant="search-toggle iq-waves-effect d-flex align-items-center"
                >

                  <img
                    src={user1}
                    className="img-fluid rounded mr-3"
                    alt="user"
                    width={'100%'}
                  />
                </Dropdown.Toggle>
              </Dropdown>
            </ul>
          </Navbar>
        </div>
      </div>
    </Fragment >
  );
});

Header.displayName = "Header";
export default Header;
