import React from 'react'
import './CardStyle.css'
import { Col, Row } from 'reactstrap'
import { Facebook, Instagram, Linkedin, Mail, MapPin, PhoneCall, Twitter } from 'react-feather'
import url from '../../../Config'
import { Link } from 'react-router-dom';

export default function centreCard({ allData }) {

    return (
        <div>
            <Row>
                {allData.map(item => {
                    return <Col md="4" lg="3" sm="12" className='mb-5 px-4'>
                        <div className="cardsStyled w-100">
                            <div className='MyWrapper'>
                                <div className='medic-button'>
                                    <div className='icon'><i><Mail /></i> </div>
                                    <span>{item.email !== '' ? item.email : 'غير متوفر'}</span>
                                </div>
                            </div>
                            <div className="profile-pic ">
                                <img src={`${url}api/centreMedicalImage/${item.salt}`} alt='' className='svg' />
                            </div>
                            <div className="bottom">
                                <div className="content">
                                    <span className="name">{item.nom}</span>
                                    <span className="about-me">{item.ville !== '' ? <span className='fw-bolder'>{item.ville} - </span> : ""} {item.adresse} </span>
                                </div>
                                <div className="bottom-bottom">
                                    <div className="social-links-container">
                                        {item.facebook !== '' ? <Link to={item.facebook} className='me-50' target='_blank'><Facebook color='#0866ff' /></Link> : ''}
                                        {item.twitter !== '' ? <Link to={item.twitter} className='me-1' target='_blank'><Twitter color='#1d9bf0' /></Link> : ''}
                                        {item.instagram !== '' ? <Link to={item.instagram} className='me-1' target='_blank'><Instagram color='#fc0b7e' /></Link> : ''}
                                        {item.linkidIn !== '' ? <Link to={item.linkidIn} className='me-1' target='_blank'><Linkedin color='#0a66c2' /></Link> : ''}

                                        {item.mapLink !== '' ? <a href={item.mapLink} rel='noreferrer' target='_blank'><MapPin color='#5c81b9' /></a> : ''}
                                    </div>
                                    <div className='MyWrapper wrapBot bg-transparent' dir='ltr'>
                                        <div className='medic-button'>
                                            <div className='icon'><i><PhoneCall /></i> </div>
                                            <span>{item.phone !== '' ? item.phone : 'غير متوفر'}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                })}

            </Row>
        </div>
    )
}
