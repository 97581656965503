import React, { Fragment } from 'react'
import { Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const RemixIconPage = () => {
  return (
    <Fragment>
        <Row>
        <Col sm='12'>
                     <div className="iq-card">
                        <div className="iq-card-header d-flex justify-content-between">
                           <div className="iq-header-title">
                              <h4 className="card-title">Remixicon Icon</h4>
                           </div>
                        </div>
                        <div className="iq-card-body">
                           <p>Use Class <code>&lt;i class="ri-4k-fill"&gt;&lt;/i&gt;</code></p>
                           <Row className="mt-3">
                              <Col sm='12' md='4' lg='2'>
                                 <Link className="iq-icons-list" to="#"><i className="ri-4k-fill"></i>4k Fill</Link>
                              </Col>
                              <Col sm='12' md='4' lg='2'>
                                 <Link className="iq-icons-list" to="#"><i className="ri-4k-line"></i>4k line</Link>
                              </Col>
                              <Col sm='12' md='4' lg='2'>
                                 <Link className="iq-icons-list" to="#"><i className="ri-account-box-fill"></i>Account Fill</Link>
                              </Col>
                              <Col sm='12' md='4' lg='2'>
                                 <Link className="iq-icons-list" to="#"><i className="ri-account-pin-box-line"></i>account-pin-box</Link>
                              </Col>
                              <Col sm='12' md='4' lg='2'>
                                 <Link className="iq-icons-list" to="#"><i className="ri-award-line"></i>award-line</Link>
                              </Col>
                              <Col sm='12' md='4' lg='2'>
                                 <Link className="iq-icons-list" to="#"><i className="ri-award-fill"></i>award-fill</Link>
                              </Col>
                              <Col sm='12' md='4' lg='2'>
                                 <Link className="iq-icons-list" to="#"><i className="ri-bookmark-line"></i>bookmark-line</Link>
                              </Col>
                              <Col sm='12' md='4' lg='2'>
                                 <Link className="iq-icons-list" to="#"><i className="ri-bookmark-fill"></i>bookmark-fill</Link>
                              </Col>
                              <Col sm='12' md='4' lg='2'>
                                 <Link className="iq-icons-list" to="#"><i className="ri-calculator-line"></i>calculator-line</Link>
                              </Col>
                              <Col sm='12' md='4' lg='2'>
                                 <Link className="iq-icons-list" to="#"><i className="ri-calculator-fill"></i>calculator-fill</Link>
                              </Col>
                              <Col sm='12' md='4' lg='2'>
                                 <Link className="iq-icons-list" to="#"><i className="ri-calendar-line"></i>calendar-line</Link>
                              </Col>
                              <Col sm='12' md='4' lg='2'>
                                 <Link className="iq-icons-list" to="#"><i className="ri-calendar-fill"></i>calendar-fill</Link>
                              </Col>
                              <Col sm='12' md='4' lg='2'>
                                 <Link className="iq-icons-list" to="#"><i className="ri-reply-line"></i>reply-line</Link>
                              </Col>
                              <Col sm='12' md='4' lg='2'>
                                 <Link className="iq-icons-list" to="#"><i className="ri-reply-fill"></i>reply-fill</Link>
                              </Col>
                              <Col sm='12' md='4' lg='2'>
                                 <Link className="iq-icons-list" to="#"><i className="ri-send-plane-line"></i>send-plane-line</Link>
                              </Col>
                              <Col sm='12' md='4' lg='2'>
                                 <Link className="iq-icons-list" to="#"><i className="ri-send-plane-fill"></i>send-plane-fill</Link>
                              </Col>
                              <Col sm='12' md='4' lg='2'>
                                 <Link className="iq-icons-list" to="#"><i className="ri-computer-line"></i>computer-line</Link>
                              </Col>
                              <Col sm='12' md='4' lg='2'>
                                 <Link className="iq-icons-list" to="#"><i className="ri-computer-fill"></i>computer-fill</Link>
                              </Col>
                              <Col sm='12' md='4' lg='2'>
                                 <Link className="iq-icons-list" to="#"><i className="ri-cellphone-line"></i>cellphone-line</Link>
                              </Col>
                              <Col sm='12' md='4' lg='2'>
                                 <Link className="iq-icons-list" to="#"><i className="ri-cellphone-fill"></i>cellphone-fill</Link>
                              </Col>
                              <Col sm='12' md='4' lg='2'>
                                 <Link className="iq-icons-list" to="#"><i className="ri-phone-line"></i>phone-line</Link>
                              </Col>
                              <Col sm='12' md='4' lg='2'>
                                 <Link className="iq-icons-list" to="#"><i className="ri-phone-fill"></i>phone-fill</Link>
                              </Col>
                              <Col sm='12' md='4' lg='2'>
                                 <Link className="iq-icons-list" to="#"><i className="ri-tablet-fill"></i>tablet-line</Link>
                              </Col>
                              <Col sm='12' md='4' lg='2'>
                                 <Link className="iq-icons-list" to="#"><i className="ri-tablet-fill"></i>tablet-fill</Link>
                              </Col>
                              <Col sm='12' md='4' lg='2'>
                                 <Link className="iq-icons-list" to="#"><i className="ri-device-fill"></i>device-line</Link>
                              </Col>
                              <Col sm='12' md='4' lg='2'>
                                 <Link className="iq-icons-list" to="#"><i className="ri-device-fill"></i>device-fill</Link>
                              </Col>
                              <Col sm='12' md='4' lg='2'>
                                 <Link className="iq-icons-list" to="#"><i className="ri-battery-line"></i>battery-line</Link>
                              </Col>
                              <Col sm='12' md='4' lg='2'>
                                 <Link className="iq-icons-list" to="#"><i className="ri-battery-fill"></i>battery-fill</Link>
                              </Col>
                              <Col sm='12' md='4' lg='2'>
                                 <Link className="iq-icons-list" to="#"><i className="ri-battery-low-fill"></i>battery-low-line</Link>
                              </Col>
                              <Col sm='12' md='4' lg='2'>
                                 <Link className="iq-icons-list" to="#"><i className="ri-battery-low-fill"></i>battery-low-fill</Link>
                              </Col>
                              <Col sm='12' md='4' lg='2'>
                                 <Link className="iq-icons-list" to="#"><i className="ri-file-line"></i>file-line</Link>
                              </Col>
                              <Col sm='12' md='4' lg='2'>
                                 <Link className="iq-icons-list" to="#"><i className="ri-file-fill"></i>file-fill</Link>
                              </Col>
                              <Col sm='12' md='4' lg='2'>
                                 <Link className="iq-icons-list" to="#"><i className="ri-book-open-line"></i>book-open-line</Link>
                              </Col>
                              <Col sm='12' md='4' lg='2'>
                                 <Link className="iq-icons-list" to="#"><i className="ri-book-open-fill"></i>book-open-fill</Link>
                              </Col>
                              <Col sm='12' md='4' lg='2'>
                                 <Link className="iq-icons-list" to="#"><i className="ri-lightbulb-line"></i>lightbulb-line</Link>
                              </Col>
                              <Col sm='12' md='4' lg='2'>
                                 <Link className="iq-icons-list" to="#"><i className="ri-lightbulb-fill"></i>lightbulb-fill</Link>
                              </Col>
                              <Col sm='12' md='4' lg='2'>
                                 <Link className="iq-icons-list" to="#"><i className="ri-map-pin-line"></i>map-pin-line</Link>
                              </Col>
                              <Col sm='12' md='4' lg='2'>
                                 <Link className="iq-icons-list" to="#"><i className="ri-map-pin-fill"></i>map-pin-fill</Link>
                              </Col>
                              <Col sm='12' md='4' lg='2'>
                                 <Link className="iq-icons-list" to="#"><i className="ri-drop-line"></i>drop-line</Link>
                              </Col>
                              <Col sm='12' md='4' lg='2'>
                                 <Link className="iq-icons-list" to="#"><i className="ri-drop-fill"></i>drop-fill</Link>
                              </Col>
                              <Col sm='12' md='4' lg='2'>
                                 <Link className="iq-icons-list" to="#"><i className="ri-drop-fill"></i>drop-fill</Link>
                              </Col>
                              <Col sm='12' md='4' lg='2'>
                                 <Link className="iq-icons-list" to="#"><i className="ri-drop-fill"></i>drop-fill</Link>
                              </Col>
                              <Col sm='12' className=" text-center mt-3">
                                 <Link to="https://remixicon.com/" target="_blank" className="btn btn-primary">View All Icon</Link>
                              </Col>
                           </Row>
                        </div>
                     </div>
                  </Col>
        </Row>
    </Fragment>
  )
}

export default RemixIconPage
