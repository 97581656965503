import React, { Fragment, useState, useEffect } from 'react';
import { Row, Col, Button, Modal, ModalBody, ModalHeader, ModalFooter, Form, Input, Label, FormGroup } from 'reactstrap';
import NavClass from './NavClass';
import TabClass from './TabClass';
import axios from 'axios';
import { toast } from 'react-toastify';
import { DangerToast, SuccessToast } from '../../../toast';
import url from '../../../Config';
import Logo from '../../../assets/images/logo.png'

const Task = () => {
    const newtaskdata = '';
    const [activeTab, setActiveTab] = useState('');
    const [cat, setCat] = useState([])
    const [data, setData] = useState([])
    const [citys, setCitys] = useState([])
    const [modal, setModal] = useState(false)
    const [formData] = useState(new FormData())
    const [titleValid, setTitleValid] = useState(null)
    const [avatar, setAvatar] = useState(Logo)
    const [steps, setSteps] = useState(1);
    const [act, setAct] = useState(0)
    const [alrt, setAlrt] = useState(false)

    const activeToggle = ((tab) => {
        setActiveTab(tab);
    });

    const onChange = e => {
        if (e.target.files.length > 0) {
            const reader = new FileReader(),
                files = e.target.files
            reader.onload = function () {
                setAvatar(reader.result)
            }
            reader.readAsDataURL(files[0])
            formData.set('image', files[0])
        }
    }

    const handelChange = e => {
        if (e.target.id === "categ") {
            const dt = JSON.parse(e.target.value)

            formData.set('categ', dt.nom)
            formData.set('categId', dt.salt)
        } else if (e.target.id === "ville") {
            const dt = JSON.parse(e.target.value)

            formData.set('ville', dt.nom)
            formData.set('villeId', dt.salt)
        } else {
            formData.set(e.target.id, e.target.value)
        }
    }

    useEffect(() => {
        axios.get(`${url}api/categories`)
            .then(response => {
                if (response.data.status === 200) {
                    const filtredData = response.data.data.filter(item => item.type === "Association")
                    const filtredDataCity = response.data.data.filter(item => item.type === "city")
                    setActiveTab(filtredData[0].salt)
                    setCat(filtredData)
                    setCitys(filtredDataCity)
                } else {
                    toast.error('Danger Notification !', {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 500
                    })
                }
            }).catch(err => toast.error(<DangerToast title={'Erreur de communication avec le serveur'} />, { hideProgressBar: true })
            )
    }, [])

    useEffect(() => {
        axios.get(`${url}api/organisations`)
            .then(response => {
                if (response.data.status === 200) {
                    const filterData = response.data.data.sort((a, b) => a.nom.localeCompare(b.nom, 'ar'))
                    setData(filterData)
                } else {
                    toast.error('Danger Notification !', {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 500
                    })
                }
            }).catch(err => toast.error(<DangerToast title={'Erreur de communication avec le serveur'} />, { hideProgressBar: true })
            )
    }, [act])

    const handelSubmit = e => {
        e.preventDefault()

        formData.set('dateValider', 'false')

        if (formData.get('nom') !== null && formData.get('nom').length > 0) {

            axios.post(`${url}api/organisation`, formData, { headers: { "Content-Type": 'multipart/form-data' } }).then((response) => {
                if (response.data.status === 200) {
                    toast.success(<SuccessToast title={'تمت الإضافة بنجاح'} />, { hideProgressBar: true })
                    setModal(false)
                    setAct(act + 1)
                    setAlrt(true)
                    setSteps(1)
                    formData.forEach(function (value, key) {
                        formData.delete(key)
                    })
                    setTitleValid(true)
                } else {

                    const message = "Erreur d'information , Veuillez réessayer plus tard "
                    toast.error(<DangerToast title={message} />, { hideProgressBar: true })

                }
            }).catch(() => {
                toast.error(<DangerToast title={'Erreur de communication avec le serveur'} />, { hideProgressBar: true })
            })
        } else {

            if (formData.get('nom') === null || formData.get('nom').length === 0) {
                setTitleValid(false)
            } else {
                setTitleValid(true)
            }
        }
    }

    const handelNext = e => {
        if (steps === 1) {
            if (formData.get('nom') === undefined || formData.get('nom') === null || formData.get('nom').length === 0) {
                setTitleValid(false)
            } else {
                setSteps((prev) => prev + 1)
                setTitleValid(true)
            }
        } else {
            setSteps((prev) => prev + 1)
        }
    }

    useEffect(() => {
        document.title = 'الجمعيات والمنظمات | المنصة الرقمية لأمراض الكلى';
    }, []);

    return cat.length > 0 && data.length > 0 ? (
        <Fragment >
            <div dir="rtl" className='mb-5'>
                <Row className='d-flex justify-content-between mb-3' >
                    <Col className='col-md-3 col-12 '>
                        <h4 className='fw-bolder'>الجمعيات والمنظمات</h4>
                    </Col>
                    <Col className="col-md-9 col-12 ">
                        <Button color='primary' style={{ marginRight: '6px' }} onClick={() => setModal(true)} >أضف جمعية أو منظمة</Button>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-md-3 col-12 ">
                        <NavClass alrt={alrt} setAlrt={setAlrt} newtaskdata={newtaskdata} activeToggle={activeToggle} cat={cat} data={data} />
                    </Col>
                    <Col className="col-md-9 col-12">
                        <TabClass activeTab={activeTab} data={data} cat={cat} citys={citys} />
                    </Col>
                </Row>
                <Modal isOpen={modal} toggle={() => setModal(false)} className='' size='xl' backdrop="static" centered>
                    <ModalHeader toggle={() => setModal(!modal)} className='text-dark'>
                    </ModalHeader>
                    <Form onSubmit={handelSubmit}>
                        <ModalBody className='bg-white' dir='rtl'>

                            {steps === 1 && <Row>
                                <Col md="6">
                                    <FormGroup>
                                        <Label for="nom">إسم الجمعية أو المنظمة</Label>
                                        <Input type='text' id='nom' onChange={handelChange} />
                                        {titleValid === false ? <span className='text-danger fw-bolder mb-1' >المرجو إدخال إسم الجمعية أو المنظمة </span> : ''}
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <Label for="categ"> التصنيف</Label>
                                        <Input type='select' id='categ' onChange={handelChange} >
                                            <option selected value={'-'}></option>
                                            {cat.map(item => <option value={JSON.stringify(item)}>{item.nom}</option>)}
                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row>}
                            {steps === 2 && <Row className=''>
                                <Label>
                                    شعار المركز الطبي  : (300 * 300)
                                </Label>
                                <Col md="6" className='d-flex justify-content-center'>
                                    <Button tag={Label} className={`m-0 p-0 w-75 `} size='md' color=''>
                                        <img src={avatar} alt='' role='button' width={'auto'} height={'180px'} />
                                        <Input type='file' onChange={onChange} hidden accept='image/*' />
                                    </Button>
                                </Col>
                                <Col md="6">
                                    <Row>
                                        <Col md="12">
                                            <FormGroup>
                                                <Label for="phone">الهاتف</Label>
                                                <Input type='text' id='phone' onChange={handelChange} />
                                            </FormGroup>
                                        </Col>
                                        <Col md="12">
                                            <FormGroup>
                                                <Label for="email">البريد الإلكتروني</Label>
                                                <Input type='text' id='email' onChange={handelChange} />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <Label for="ville"> المدينة</Label>
                                        <Input type='select' id='ville' onChange={handelChange} >
                                            <option selected value={'-'}></option>
                                            {citys.map(item => <option value={JSON.stringify(item)}>{item.nom}</option>)}
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <Label for="adresse">العنوان</Label>
                                        <Input type='text' id='adresse' onChange={handelChange} />
                                    </FormGroup>
                                </Col>
                                <Col md="12">
                                    <FormGroup>
                                        <Label for="mapLink">رابط الخريطة</Label>
                                        <Input type='text' id='mapLink' onChange={handelChange} />
                                    </FormGroup>
                                </Col>

                            </Row>}
                            {steps === 3 && <Row className=''>

                                <Col md="6">
                                    <FormGroup>
                                        <Label for="facebook">الفيسبوك</Label>
                                        <Input type='text' id='facebook' onChange={handelChange} />
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <Label for="twitter">تويتر</Label>
                                        <Input type='text' id='twitter' onChange={handelChange} />
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <Label for="linkidIn">لينكد إن</Label>
                                        <Input type='text' id='linkidIn' onChange={handelChange} />
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <Label for="instagram">انستغرام</Label>
                                        <Input type='text' id='instagram' onChange={handelChange} />
                                    </FormGroup>
                                </Col>
                            </Row>}{" "}
                            <div className="text-center">
                                <span className={`step ${steps > 1 ? "finish" : ""} ${steps === 1 ? "active" : ""}`} />
                                <span className={`step ${steps > 2 ? "finish" : ""} ${steps === 2 ? "active" : ""}`} />
                                <span className={`step ${steps > 3 ? "finish" : ""} ${steps === 3 ? "active" : ""}`} />{" "}
                            </div>
                        </ModalBody>
                        <ModalFooter className='d-flex justify-content-between bg-white '>
                            {steps < 3 ? <Button color='primary' type="button" onClick={handelNext}>
                                التالي
                            </Button> : ''}


                            {steps === 3 ? <Button color='primary'>أضف</Button> : ''}
                        </ModalFooter>
                    </Form>
                </Modal>
            </div>
        </Fragment >
    ) : ''
};

export default Task;