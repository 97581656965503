// ** React Imports
import { Fragment } from 'react'
import 'react-toastify/dist/ReactToastify.css'


export const SuccessToast = ({ title }) => {

  return (
    <Fragment>
      {/* <ToastContainer /> <span className="text-light">{title} {user}</span> */}
      {/* <div className='toastify-header'>
        <div className='title-wrapper'>
          <Avatar size='sm' color='success' icon={<Check size={12} />} />
          <h6 className='toast-title'> {user ? user : 'Félicitation !'}</h6>
        </div>
      </div> */}
      <div >
        <span className="text-dark">
          {title}
        </span>
      </div>
    </Fragment>
  )
}

export const successMsg = "L'operation succès"

export const DangerToast = ({ title }) => {

  return (
    <Fragment>
      {/* <ToastContainer /> <span className="text-light">{title}</span> */}
      <div >
        <span className="text-dark">
          {title}
        </span>
      </div>
    </Fragment>
  )
}

