import { Fragment, useEffect, useState } from "react";
import { Card, Col, Row, FormGroup, Button, FormSelect } from "react-bootstrap";
import Masonry from "react-masonry-css";
import url from "../../../Config";
import axios from "axios";
import { toast } from "react-toastify";
import { DangerToast } from "../../../toast";
import { Label } from "reactstrap";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "../../../assets/css/Question.css";

const UiCards = () => {
  const breakpointColumnsObj = {
    default: 4,
    1199: 3,
    700: 2,
    500: 1,
  };

  const [data, setData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [cat, setCat] = useState([]);
  const [years, setYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedCat, setSelectedCat] = useState("");
  const [visibleData, setVisibleData] = useState([]);
  const [loadMoreCount, setLoadMoreCount] = useState(8);
  const navigate = useNavigate();

  const handelFilter = (e) => {
    let filtredData = [];
    if (e.target.value !== "-" && selectedYear !== "") {
      filtredData = allData.filter(
        (item) =>
          item.categId === e.target.value &&
          moment(item.createdAt).format("YYYY") ===
            moment(e.target.value).format("YYYY")
      );
    } else if (e.target.value !== "-" && selectedYear === "") {
      filtredData = allData.filter((item) => item.categId === e.target.value);
    } else if (e.target.value === "-" && selectedYear !== "") {
      filtredData = allData.filter(
        (item) =>
          moment(item.createdAt).format("YYYY") ===
          moment(e.target.value).format("YYYY")
      );
    } else {
      filtredData = allData;
    }
    setData(filtredData);
  };

  const handelFilterDate = (e) => {
    let filtredData = [];

    if (e.target.value !== "-" && selectedCat !== "") {
      filtredData = allData.filter(
        (item) =>
          moment(item.createdAt).format("YYYY") ===
            moment(e.target.value).format("YYYY") &&
          item.categId === selectedCat
      );
    } else if (e.target.value !== "-" && selectedCat === "") {
      filtredData = allData.filter(
        (item) =>
          moment(item.createdAt).format("YYYY") ===
          moment(e.target.value).format("YYYY")
      );
    } else if (e.target.value === "-" && selectedCat !== "") {
      filtredData = allData.filter((item) => item.categId === selectedCat);
    } else {
      filtredData = allData;
    }

    setData(filtredData);
  };

  useEffect(() => {
    axios
      .get(`${url}api/categories`)
      .then((response) => {
        if (response.data.status === 200) {
          const filtredData = response.data.data.filter(
            (item) => item.type === "News"
          );
          setCat(filtredData);
        } else {
          toast.error("Danger Notification !", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 500,
          });
        }
      })
      .catch((err) =>
        toast.error(
          <DangerToast title={"Erreur de communication avec le serveur"} />,
          { hideProgressBar: true }
        )
      );
  }, []);

  useEffect(() => {
    let year = [];
    for (let i = 2022; i <= 2023; i++) {
      year = [...year, <option>{i}</option>];
    }

    setYears(year);
    axios
      .get(`${url}api/newsMedicals`)
      .then((response) => {
        if (response.data.status === 200) {
          setData((prevData) => [...prevData, ...response.data.data]);

          setAllData(response.data.data);
        } else {
          toast.error("Danger Notification !", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 500,
          });
        }
      })
      .catch((err) =>
        toast.error(
          <DangerToast title={"Erreur de communication avec le serveur"} />,
          { hideProgressBar: true }
        )
      );
  }, []);

  // useEffect(() => {
  //     axios.get(`${url}api/maladieReins`)
  //         .then(response => {
  //             if (response.data.status === 200) {
  //                 setData(response.data.data)
  //             } else {
  //                 toast.error('Danger Notification !', {
  //                     position: toast.POSITION.TOP_RIGHT,
  //                     autoClose: 500
  //                 })
  //             }
  //         }).catch(err => toast.error(<DangerToast title={'Erreur de communication avec le serveur'} />, { hideProgressBar: true })
  //         )
  // }, [])

  const handelSaveDetails = (e) => {
    console.log(e);
    localStorage.setItem("detailsOfNews", JSON.stringify(e));
    setTimeout(() => {
      navigate("/NewsDetails");
    }, 500);
  };

  useEffect(() => {
    if (data.length > 0) {
      // Update the visible data based on the loadMoreCount
      setVisibleData(data.slice(0, loadMoreCount));
    }
  }, [data, loadMoreCount]);

  const loadMore = () => {
    setLoadMoreCount(loadMoreCount + 8);
  };

  return (
    <Fragment>
      <div className="container-fluid-content">
        <Row dir="rtl" className="align-items-center">
          <Col md="6">
            <Label className="h5 fw-bolder text-dark">تصنيف الاخبار</Label>
          </Col>
          <Col>
            <FormGroup className="form-group">
              <FormSelect
                className="form-select my-2"
                id="exampleFormControlSelect1"
                onChange={(e) => {
                  if (e.target.value !== "-") {
                    setSelectedCat(e.target.value);
                  } else {
                    setSelectedCat("");
                  }
                  handelFilter(e);
                }}
              >
                <option value={"-"} defaultValue>
                  جميع التصنيفات
                </option>
                {cat.map((item) => (
                  <option value={item.salt}>{item.nom}</option>
                ))}
              </FormSelect>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup className="form-group">
              <FormSelect
                className="form-select my-2"
                id="exampleFormControlSelect1"
                onChange={(e) => {
                  console.log(e.target.value);
                  if (e.target.value !== "-") {
                    setSelectedYear(moment(e.target.value).format("YYYY"));
                  } else {
                    setSelectedYear("");
                  }
                  handelFilterDate(e);
                }}
              >
                <option value={"-"} defaultValue>
                  السنوات
                </option>
                {years}
              </FormSelect>
            </FormGroup>
          </Col>
        </Row>
        {visibleData.length > 0 ? (
          <>
            <Row dir="rtl">
              <Col sm="12">
                <h3 className="mb-3">
                  الأخبار و المستجدات الطبية للقصور الكلوي
                </h3>
                <div className="row">
                  <Masonry
                    breakpointCols={breakpointColumnsObj}
                    className="my-masonry-grid masonry-with-dec my-gallery gallery-with-description row grid"
                    columnClassName="col-xl-3 col-sm-6 col-md-4 grid-item"
                  >
                    {visibleData.map((element, index) => {
                      const text = `${element.content.slice(0, 300)} ...`;
                      return (
                        <li style={{ listStyle: "none" }}>
                          <Card className="iq-mb-3">
                            <Card.Img
                              variant="top"
                              src={`${url}api/newsMedicalImage/${element.salt}`}
                              alt="#"
                            />
                          </Card>

                          <Card className="iq-mb-3">
                            <Card.Body>
                              <Card.Title>{element.titre}</Card.Title>
                              {element.content.length >= 10 ? (
                                <Card.Text>
                                  <div
                                    dangerouslySetInnerHTML={{ __html: text }}
                                  ></div>
                                  <span
                                    onClick={() => handelSaveDetails(element)}
                                    role="button"
                                  >
                                    اقرأ المزيد
                                  </span>
                                </Card.Text>
                              ) : (
                                <Card.Text>
                                  <small className="text-muted">
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: element.content,
                                      }}
                                    ></div>
                                  </small>
                                </Card.Text>
                              )}
                            </Card.Body>
                          </Card>
                        </li>
                      );
                    })}
                  </Masonry>
                </div>
              </Col>
            </Row>
            {data.length > visibleData.length && (
              <div className="d-flex justify-content-center">
                <Button color="primary" onClick={loadMore}>
                  أظهر المزيد
                </Button>
              </div>
            )}
          </>
        ) : (
          <Fragment>
            <Row className="my-5">
              <Masonry
                breakpointCols={breakpointColumnsObj}
                className="my-masonry-grid masonry-with-dec my-gallery gallery-with-description row grid"
                columnClassName="col-xl-3 col-sm-12 col-md-4 grid-item"
              >
                <Skeleton height={400} className="my-4" />

                <Skeleton height={350} className="my-4" />

                <Skeleton height={400} className="my-4" />

                <Skeleton height={250} className="my-4" />

                <Skeleton height={300} className="my-4" />

                <Skeleton height={200} className="my-4" />

                <Skeleton height={220} className="my-4" />

                <Skeleton height={280} className="my-4" />

                <Skeleton height={180} className="my-4" />

                <Skeleton height={280} className="my-4" />

                <Skeleton height={280} className="my-4" />

                <Skeleton height={280} className="my-4" />
              </Masonry>
            </Row>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

export default UiCards;
