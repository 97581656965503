import React, { Fragment, useState } from 'react';
import { Card, CardBody, NavItem } from 'reactstrap';
import { List } from 'react-feather';
import { Badge, Nav, Tab, Alert } from "react-bootstrap";
import './tabsStyle.css'

const NavClass = ({ activeToggle, cat, data, alrt, setAlrt }) => {
    const [activeTab, setActiveTab] = useState(cat[0].salt);

    return (
        <Fragment>
            <div className="">
                <Tab.Container defaultActiveKey={"v-pills-home"}>
                    <div className="iq-card-header d-flex justify-content-between my-3">
                        <div className="iq-header-title">
                            <h4 className="card-title"><List className="me-2" /> تصنيفات البحث</h4>
                        </div>
                    </div>

                    <Card>
                        <CardBody>
                            <div className="iq-card-body">
                                <Nav
                                    className="nav flex-column nav-pills text-right"
                                    id="v-pills-tab"
                                    role="tablist"
                                    aria-orientation="vertical"
                                >
                                    {cat.map((item) => {

                                        return <Nav.Link
                                            data-bs-toggle="pill"
                                            eventKey={item.salt}
                                            role="tab"
                                            aria-controls={item.salt}
                                            aria-selected={activeTab === item.salt ? true : false}
                                            className={`d-flex justify-content-between align-items-center  ${activeTab === item.salt ? 'active' : ''}`}
                                            onClick={() => {
                                                setActiveTab(item.salt);
                                                activeToggle(item.salt);
                                            }}
                                        >
                                            <span className="title">{item.nom}</span>
                                            <Badge bg={`${data.filter(col => col.categId === item.salt).length > 0 ? 'primary' : 'warning'}`} className="badge-pill">
                                                {data.filter(col => col.categId === item.salt).length}
                                            </Badge>
                                        </Nav.Link>
                                    })}
                                </Nav>
                            </div>
                            <div className="email-app-sidebar left-bookmark d-none">
                                <Nav className="main-menu" role="tablist">
                                    <NavItem>
                                        <button className="badge-light btn-block btn-mail"><List className="me-2" />{'تصنيفات البحث'}</button>
                                    </NavItem>

                                    {cat.map((item) => {
                                        return <NavItem key={1}>
                                            <div role='button' className={`tabsFilter ${activeTab === item.salt ? 'active' : ''}`} onClick={() => {
                                                setActiveTab(item.salt); activeToggle(item.salt);
                                            }}>
                                                <span className="title">{item.nom}</span>
                                            </div>
                                        </NavItem>
                                    })}
                                    <li>
                                        <hr />
                                    </li>
                                </Nav>
                            </div>
                        </CardBody>
                    </Card>
                </Tab.Container>
                {alrt && <Alert variant="success" className='mt-5'>
                    <div className="iq-alert-icon">
                        <i className="ri-alert-fill"></i>
                    </div>
                    <div className="iq-alert-text">
                        نرحب بكم في منصتنا، سيتم التحقق من طلبك خلال 72 ساعة. <b>شكرًا</b>
                    </div>
                    <div className='d-none'>
                        {setTimeout(() => {
                            setAlrt(false)
                        }, 3000)}
                    </div>
                </Alert>}
            </div >
        </Fragment >
    );
};

export default NavClass;