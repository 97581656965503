import React, { Fragment, useState } from 'react';
import { Nav } from 'react-bootstrap'

const SearchTabs = ({ callback, cat }) => {
  const [activeTab, setActiveTab] = useState(cat[0].salt);

  return callback !== undefined && (
    <Fragment>
      <Nav
        as={"ul"}
        className="nav nav-pills mb-3 justify-content-center"
        id="pills-tab"
        role="tablist"
      >
        {cat.map((item) => {
          return <Nav.Item as={"li"}>
            <Nav.Link
              id={item.salt}
              data-bs-toggle="pill"
              eventKey={item.salt}
              role="tab"
              aria-controls={item.salt}
              aria-selected={activeTab === item.salt ? 'active' : ''}
              className={activeTab === item.salt ? 'active' : ''}
              onClick={() => {
                setActiveTab(item.salt);
                callback(item.salt);
              }}
            >
              {item.nom}
            </Nav.Link>
          </Nav.Item>
        })}
      </Nav>
    </Fragment>
  );
};
export default SearchTabs;