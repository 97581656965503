import React, { Fragment, useState } from 'react';
import { Col, Row, Card, CardBody, Modal, ModalBody } from 'reactstrap';
import { X } from 'react-feather';
import url from '../../../Config';
import './TransparantModal.css'
import ReactPlayer from 'react-player'

const PhotosTab = ({ cat, allData }) => {
    const [modalData, setModalData] = useState(null)

    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    const handelOpenModal = (item) => {
        setModalData(item)
        toggle()
    }


    const renderDaa = allData.map((item, index) => {
        return (
            <figure itemProp="associatedMedia" className="col-md-3 col-12 img-hover hover-15 rounded-3" key={index} role='button' onClick={() => handelOpenModal(item)}>
                <div className="">
                    <img
                        src={`${url}api/guideMedicalImage/${item.salt}`}
                        alt={item.titre}
                        className={'img-fluid rounded-3'}
                        onClick={() => handelOpenModal(item)}
                    />
                </div>
            </figure>
        )

    })


    return (
        <Fragment>
            <Row>
                <Col sm="12">
                    <Card>
                        <CardBody className="my-gallery row gallery-with-description">
                            <div id="aniimated-thumbnials14" className="row gallery my-gallery" style={{ display: 'contents !important' }}>
                                {renderDaa}
                            </div>
                        </CardBody>
                    </Card>
                </Col>
                <Modal isOpen={modal} toggle={() => setModal(false)} className='text-danger bg-transparent' size='xl' centered>
                    {modalData !== null ? <ModalBody className='bg-transparent border-0 '>
                        <div className='d-flex justify-content-between '>
                            <span className='h5'>{modalData.titre}</span>
                            <X role='button' onClick={() => setModal(false)} />
                        </div>

                        <ReactPlayer
                            width='100%'
                            height={'500px'}
                            controls={true}
                            className='react-player-video'
                            url={`${modalData.link}`}
                        />
                    </ModalBody> : ''}
                </Modal>
            </Row>

        </Fragment>
    );
};

export default PhotosTab;