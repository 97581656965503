import React, { Fragment, useState } from 'react'
import { Card, CardBody, Col, Row, Modal, ModalBody, Media } from 'reactstrap';
import url from '../../../Config';
import { Link } from 'react-router-dom';
import Masonry from 'react-masonry-css';
// import { X } from 

export default function NewsDetails() {
    const data = JSON.parse(localStorage.getItem('detailsOfNews'))
    const [modal, setModal] = useState(false)
    const [modalData, setModalData] = useState([])
    const toggle = () => setModal(!modal)
    const handelOpenModal = (item) => {
        setModalData(item)
        toggle()
    }

    return (
        <div dir='rtl' className='mb-5'>
            <Fragment>
                <div className="blog-box blog-details">
                    <Row>
                        <Card className='col-md-6 col-12'>
                            <div className='d-flex justify-content-between my-3'>
                                <span>
                                    <Link to={'/'}>الرئيسية</Link>
                                    <span className='mx-2'>/</span>
                                    <Link to={'/MedicalNews'}>الأخبار والمستجدات  </Link>
                                </span>
                            </div>

                            <Row>

                                <CardBody className="photoswipe-pb-responsive">
                                    <Masonry
                                        breakpointCols={2}
                                        className="my-gallery row grid gallery"
                                        columnClassName="col-md-3 col-6 grid-item">
                                        <div key={0} className='mb-1' onClick={() => handelOpenModal(`${url}api/newsMedicalImage/${data.salt}`)}>
                                            <Media src={`${url}api/newsMedicalImage/${data.salt}`} style={{ width: '100%' }} alt="" className='rounded' role='button' />
                                        </div>
                                        {data.image1 !== "" ? <div className='mb-1' key={1} onClick={() => handelOpenModal(`${url}api/newsMedicalImage1/${data.salt}`)}>
                                            <Media src={`${url}api/newsMedicalImage1/${data.salt}`} style={{ width: '100%' }} alt="" className='rounded' role='button' />
                                        </div> : ""}
                                        {data.image2 !== "" ? <div className='mb-1' key={2} onClick={() => handelOpenModal(`${url}api/newsMedicalImage2/${data.salt}`)}>
                                            <Media src={`${url}api/newsMedicalImage2/${data.salt}`} style={{ width: '100%' }} alt="" className='rounded' role='button' />
                                        </div> : ""}
                                        {data.image3 !== "" ? <div className='mb-1' key={3} onClick={() => handelOpenModal(`${url}api/newsMedicalImage3/${data.salt}`)}>
                                            <Media src={`${url}api/newsMedicalImage3/${data.salt}`} style={{ width: '100%' }} alt="" className='rounded' role='button' />
                                        </div> : ""}
                                        {data.image4 !== "" ? <div className='mb-1' key={4} onClick={() => handelOpenModal(`${url}api/newsMedicalImage4/${data.salt}`)}>
                                            <Media src={`${url}api/newsMedicalImage4/${data.salt}`} style={{ width: '100%' }} alt="" className='rounded' role='button' />
                                        </div> : ""}
                                        {/* )} */}
                                    </Masonry>
                                </CardBody>
                            </Row>
                            <Row className='d-none'>
                                {data.image1 !== "" ? <Col md="6" onClick={() => handelOpenModal(`${url}api/newsMedicalImage1/${data.salt}`)} >
                                    <img src={`${url}api/newsMedicalImage1/${data.salt}`} alt='' width='100%' role="button" />
                                </Col> : ""}
                                {data.image2 !== "" ? <Col md="6" onClick={() => handelOpenModal(`${url}api/newsMedicalImage2/${data.salt}`)} >
                                    <img src={`${url}api/newsMedicalImage2/${data.salt}`} alt='' width='100%' role="button" />
                                </Col> : ""}
                                <Col md="12" className='my-4' onClick={() => handelOpenModal(`${url}api/newsMedicalImage/${data.salt}`)} >
                                    <img src={`${url}api/newsMedicalImage/${data.salt}`} alt='' width='100%' role="button" />
                                </Col>
                                {data.image3 !== "" ? <Col md="6" onClick={() => handelOpenModal(`${url}api/newsMedicalImage3/${data.salt}`)} >
                                    <img src={`${url}api/newsMedicalImage3/${data.salt}`} alt='' width='100%' role="button" />
                                </Col> : ""}
                                {data.image4 !== "" ? <Col md="6" onClick={() => handelOpenModal(`${url}api/newsMedicalImage4/${data.salt}`)} >
                                    <img src={`${url}api/newsMedicalImage4/${data.salt}`} alt='' width='100%' role="button" />
                                </Col> : ""}
                            </Row>

                        </Card>
                        <Card className='col-md-6 col-12'>
                            <span className='my-3'>تاريخ النشر : {data.date} </span>

                            <CardBody>
                                <div className="blog-details">
                                    <h4>
                                        {data.titre}
                                    </h4>
                                    <div className="single-blog-content-top">
                                        <p>
                                            <div dangerouslySetInnerHTML={{ __html: data.content }} ></div>
                                        </p>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Row>
                </div>
                <Modal isOpen={modal} toggle={() => setModal(false)} className='text-danger bg-transparent' size='xl' centered>
                    {modalData !== null ? <ModalBody className='bg-transparent border-0 '>
                        <div className='d-flex justify-content-between '>
                            <span className='h5'>{modalData.titre}</span>
                            {/* <X role='button' onClick={() => setModal(false)} /> */}
                        </div>
                        <img src={modalData} alt='' style={{ maxHeight: 'auto', width: '100%' }} />
                    </ModalBody> : ''}
                </Modal>
            </Fragment>
        </div>
    )
}
