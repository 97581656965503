import React, { useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap';
import { Modal, ModalBody } from 'reactstrap';
import url from '../../../Config';
import ReactPlayer from 'react-player'
import { Eye, X } from 'react-feather';
import '../../../assets/css/hoverProduct.css'

export default function Elements({ visibleData }) {
    const [modalData, setModalData] = useState(null)
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);


    const handelOpenModal = (item) => {
        console.log(modalData)
        setModalData(item)
        toggle()
    }
    console.log(visibleData)
    return (
        <div>
            <Row className='mt-4'>
                {visibleData.map((item, index) => {
                    return (<Col sm='3' key={index} role='button' onClick={() => handelOpenModal(item)}>
                        <Card className="iq-mb-3">
                            <div className="product-img">
                                <Card.Img variant='top' className='img imgHvr' src={`${url}api/kidneyTransplantImage/${item.salt}`} alt="#" />
                                <div className="product-hover py-5" onClick={() => handelOpenModal(item)}>
                                    <ul className='simple-list  flex-row p-0' >
                                        <li>
                                            <Eye color='#212529' style={{ opacity: '1 !important' }} />
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <Card.Body>
                                <Card.Title as="h4"> {item.titre}</Card.Title>
                                <Card.Text>
                                    <div dangerouslySetInnerHTML={{ __html: item.content }} ></div>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    );
                })
                }
            </Row>

            <Modal isOpen={modal} toggle={() => setModal(false)} className='text-danger bg-transparent' size='xl' centered>
                {modalData !== null ? <ModalBody className='bg-transparent border-0 '>
                    <div className='d-flex justify-content-between '>
                        <span className='h5'>{modalData.titre}</span>
                        <X role='button' onClick={() => setModal(false)} />
                    </div>
                    <ReactPlayer
                        width='100%'
                        height={'500px'}
                        controls={true}
                        className='react-player-video'
                        url={`${modalData.link}`}
                    />
                </ModalBody> : ''}
            </Modal>
        </div >
    )
}
