import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { Col, Row, Tab } from 'react-bootstrap'
import url from '../../../Config';
import axios from 'axios';
import { toast } from 'react-toastify';
import { DangerToast } from '../../../toast'
import AllTabs from './AllTabs';
import SearchTabs from './Tabs'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'


export default function Index() {
    const [activeTab, setActiveTab] = useState('');
    const [cat, setCat] = useState([])
    const [data, setData] = useState([])
    const callback = useCallback((tab) => {
        setActiveTab(tab);
    }, []);

    useEffect(() => {
        axios.get(`${url}api/categories`)
            .then(response => {
                if (response.data.status === 200) {
                    const filtredData = response.data.data.filter(item => item.type === "Guide")
                    setActiveTab(filtredData[0].salt)
                    setCat(filtredData)

                } else {
                    toast.error('Danger Notification !', {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 500
                    })
                }
            }).catch(err => toast.error(<DangerToast title={'Erreur de communication avec le serveur'} />, { hideProgressBar: true })
            )
    }, [])

    useEffect(() => {
        axios.get(`${url}api/guideMedicals`)
            .then(response => {
                if (response.data.status === 200) {
                    setData(response.data.data)
                } else {
                    toast.error('Danger Notification !', {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 500
                    })
                }
            }).catch(err => toast.error(<DangerToast title={'Erreur de communication avec le serveur'} />, { hideProgressBar: true })
            )
    }, [])

    useEffect(() => {
        document.title = 'القناة الطبية | المنصة الرقمية لأمراض الكلى';
    }, []);

    return cat.length > 0 && data.length > 0 ? (
        <div dir='rtl' className='mb-5 container-fluid-content'>
            <Row >
                <div className="iq-card">
                    <Tab.Container defaultActiveKey={"pills-home"}>
                        <div className="iq-card-header d-flex justify-content-center">
                            <div className="iq-header-title">
                                <h3 className="card-title ">دليلك الطبي لمرض القصور الكلوي</h3>
                            </div>
                        </div>
                        <div className="iq-card-body">
                            <SearchTabs callback={callback} cat={cat} />
                            <Tab.Content className="tab-content" activeTab={activeTab} id="pills-tabContent-2">
                                {cat.map(item => {
                                    return <Tab.Pane
                                        className={`fade show ${activeTab === item.salt ? 'active' : ''}`}
                                        eventKey={item.salt}
                                        role="tabpanel"
                                        aria-labelledby={item.salt}
                                    >
                                        <AllTabs
                                            cat={item}
                                            allData={data.filter(col => col.categId === item.salt)}
                                        />
                                    </Tab.Pane>
                                }
                                )}
                            </Tab.Content>
                        </div>
                    </Tab.Container>
                </div>
            </Row>
        </div>
    ) : <Fragment>
        <Row className='d-flex justify-content-center'>
            <Col md={3} >
                <Skeleton height={'40px'} />
            </Col>
        </Row>
        <Row className='d-flex justify-content-center my-5'>
            <Col md={1} >
                <Skeleton height={'40px'} />
            </Col>
            <Col md={1} >
                <Skeleton height={'40px'} />
            </Col>
            <Col md={1} >
                <Skeleton height={'40px'} />
            </Col>
            <Col md={1} >
                <Skeleton height={'40px'} />
            </Col>
            <Col md={1} >
                <Skeleton height={'40px'} />
            </Col>
        </Row>
        <Row className='d-flex justify-content-center my-5'>
            <Col md={3} >
                <Skeleton height={'250px'} />
            </Col>
            <Col md={3} >
                <Skeleton height={'250px'} />
            </Col>
            <Col md={3} >
                <Skeleton height={'250px'} />
            </Col>
            <Col md={3} >
                <Skeleton height={'250px'} />
            </Col>
        </Row>
        <Row className='d-flex justify-content-center my-5'>
            <Col md={3} >
                <Skeleton height={'250px'} />
            </Col>
            <Col md={3} >
                <Skeleton height={'250px'} />
            </Col>
            <Col md={3} >
                <Skeleton height={'250px'} />
            </Col>
            <Col md={3} >
                <Skeleton height={'250px'} />
            </Col>
        </Row>
    </Fragment>
}
