import React, { Fragment, useEffect, useState } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import axios from 'axios'
import url from '../../../Config'
import Elements from './Elements'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import '../../../assets/css/catTransplant.scss'

export default function Index() {
    const [data, setData] = useState([])
    const [visibleData, setVisibleData] = useState([]);
    const [loadMoreCount, setLoadMoreCount] = useState(8)
    const [categorie, setCategorie] = useState('الجانب الشرعي')

    useEffect(() => {
        axios.get(`${url}api/kidneyTransplants`)
            .then(response => {
                const filtredData = response.data.data.filter(item => item.categorie === categorie)
                setData(filtredData);
            })
            .catch(err => console.log(err))

        return () => {
            setData([])
        }
    }, [categorie])

    useEffect(() => {
        if (data.length > 0) {
            // Update the visible data based on the loadMoreCount
            setVisibleData(data.slice(0, loadMoreCount));
        }
    }, [data, loadMoreCount]);

    const loadMore = () => {
        setLoadMoreCount(loadMoreCount + 8);
    };


    useEffect(() => {
        document.title = 'زراعة الكلى | المنصة الرقمية لأمراض الكلى';
    }, [])

    return (
        <div dir='rtl' className='  '>
            <Row className='text-center bg-primary pt-5 pb-5 text-center rounded-4 '>
                <h3 className='text-light'>زراعة الكلى هي إجراء طبي حيوي يمكن أن ينقذ حياة الأشخاص الذين يعانون من فشل كلوي.</h3>
                <h3 className='fw-bolder text-light'>هذا الإجراء يتيح للمرضى العودة إلى حياة صحية ونشطة دون الحاجة للغسيل الكلوي الدائم.</h3>
            </Row>

            <Row className='mt-5 d-flex justify-content-center'>

                <div className="col-lg-2 col-md-6 text-center" role='button'    >
                    <p className="iq-bg-primary pt-5 pb-5 text-center rounded-4 font-size-32 slide" onClick={() => setCategorie('الجانب الشرعي')}>الجانب الشرعي</p>
                </div>

                <div className="col-lg-2 col-md-6 text-center" role='button'>
                    <p className="iq-bg-primary pt-5 pb-5 text-center rounded-4 font-size-32 slide" onClick={() => setCategorie('الجانب القانوني')}>الجانب القانوني</p>
                </div>
                <div className="col-lg-2 col-md-6 text-center" role='button'>
                    <p className="iq-bg-primary pt-5 pb-5 text-center rounded-4 font-size-32 slide" onClick={() => setCategorie('الجانب الإجتماعي')}>الجانب الإجتماعي</p>
                </div>
                <div className="col-lg-2 col-md-6 text-center" role='button'>
                    <p className="iq-bg-primary pt-5 pb-5 text-center rounded-4 font-size-32 slide" onClick={() => setCategorie('الجانب الطبي')}>الجانب الطبي</p>
                </div>
                <div className="col-lg-2 col-md-6 text-center" role='button'>
                    <p className="iq-bg-primary pt-5 pb-5 text-center rounded-4 font-size-32 slide" onClick={() => setCategorie('شهادات')}>شهادات</p>
                </div>
            </Row>
            {data.length > 0 ? <>
                <Elements visibleData={visibleData} />

                {data.length > visibleData.length && (
                    <div className='d-flex justify-content-center mb-4'>
                        <Button color='primary' onClick={loadMore}>أظهر المزيد</Button>
                    </div>
                )}</> : <Fragment>

                <Row className='mb-5'>
                    <Col md={3}>
                        <Skeleton height={'300px'} />
                    </Col>
                    <Col md={3}>
                        <Skeleton height={'300px'} />
                    </Col>
                    <Col md={3}>
                        <Skeleton height={'300px'} />
                    </Col>
                    <Col md={3}>
                        <Skeleton height={'300px'} />
                    </Col>
                </Row>
            </Fragment>}
        </div>
    )
}
