import { useState, useEffect, Fragment } from 'react'
import CardAssociation from './CardAssociation'
import { Row, Col, Button, Modal, ModalBody, ModalHeader, ModalFooter, Form, Input, Label, FormGroup } from 'reactstrap';
import { Plus } from 'react-feather'
import Association from './associations-publiques.png'
import Enteprise from './entreprise.png'
import { FormControl, FormSelect } from 'react-bootstrap';
import axios from 'axios';
import url from '../../../Config';
import Logo from '../../../assets/images/logo.png'
import { toast } from 'react-toastify';
import { DangerToast, SuccessToast } from '../../../toast';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import './style.css'

export default function Index() {
    const [activeTab, setActiveTab] = useState('');
    const [filtredBigData, setFiltredData] = useState([])
    const [modal, setModal] = useState(false)
    const [data, setData] = useState([])
    const [formData] = useState(new FormData())
    const [steps, setSteps] = useState(1);
    const [citys, setCitys] = useState([])
    const [avatar, setAvatar] = useState(Logo)
    const [cat, setCat] = useState([])
    const [titleValid, setTitleValid] = useState(null)
    const [act, setAct] = useState(0)
    const [srxName, setSrxName] = useState('')
    const [slctCity, setSlctCity] = useState('')


    const onChange = e => {
        if (e.target.files.length > 0) {
            const reader = new FileReader(),
                files = e.target.files
            reader.onload = function () {
                setAvatar(reader.result)
            }
            reader.readAsDataURL(files[0])
            formData.set('image', files[0])
        }
    }

    const handelChange = e => {
        if (e.target.id === "categ") {
            const dt = JSON.parse(e.target.value)

            formData.set('categ', dt.nom)
            formData.set('categId', dt.salt)
        } else if (e.target.id === "ville") {
            const dt = JSON.parse(e.target.value)

            formData.set('ville', dt.nom)
            formData.set('villeId', dt.salt)
        } else {
            formData.set(e.target.id, e.target.value)
        }
    }

    const handelNext = e => {
        if (steps === 1) {
            if (formData.get('nom') === undefined || formData.get('nom') === null || formData.get('nom').length === 0) {
                setTitleValid(false)
            } else {
                setSteps((prev) => prev + 1)
                setTitleValid(true)
            }
        } else {
            setSteps((prev) => prev + 1)
        }
    }

    const handelSubmit = e => {
        e.preventDefault()

        formData.set('dateValider', 'false')

        if (formData.get('nom') !== null && formData.get('nom').length > 0) {

            axios.post(`${url}api/organisation`, formData, { headers: { "Content-Type": 'multipart/form-data' } }).then((response) => {
                if (response.data.status === 200) {
                    toast.success(<SuccessToast title={'تمت الإضافة بنجاح'} />, { hideProgressBar: true })
                    setModal(false)
                    setAct(act + 1)

                    setSteps(1)
                    formData.forEach(function (value, key) {
                        formData.delete(key)
                    })
                    setTitleValid(true)
                } else {

                    const message = "Erreur d'information , Veuillez réessayer plus tard "
                    toast.error(<DangerToast title={message} />, { hideProgressBar: true })

                }
            }).catch(() => {
                toast.error(<DangerToast title={'Erreur de communication avec le serveur'} />, { hideProgressBar: true })
            })
        } else {

            if (formData.get('nom') === null || formData.get('nom').length === 0) {
                setTitleValid(false)
            } else {
                setTitleValid(true)
            }
        }
    }

    useEffect(() => {
        axios.get(`${url}api/categories`)
            .then(response => {
                if (response.data.status === 200) {
                    const filtredData = response.data.data.filter(item => item.type === "Association")
                    const filtredDataCity = response.data.data.filter(item => item.type === "city")
                    setActiveTab('الجمعيات')
                    setCat(filtredData)
                    setCitys(filtredDataCity)
                } else {
                    toast.error('Danger Notification !', {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 500
                    })
                }
            }).catch(err => toast.error(<DangerToast title={'Erreur de communication avec le serveur'} />, { hideProgressBar: true })
            )
    }, [])

    useEffect(() => {
        axios.get(`${url}api/organisations`)
            .then(response => {
                if (response.data.status === 200) {
                    const filterData = response.data.data.filter(item => item.dateValider !== "false").sort((a, b) => a.nom.localeCompare(b.nom, 'ar'))

                    setFiltredData(filterData.filter(item => item.categ === activeTab))
                    setData(filterData)
                } else {
                    toast.error('Danger Notification !', {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 500
                    })
                }
            }).catch(err => toast.error(<DangerToast title={'Erreur de communication avec le serveur'} />, { hideProgressBar: true })
            )
    }, [act, activeTab])

    const handelFilter = e => {
        let filtredData
        let startsWith
        let includes

        if (e.target.value !== '' && slctCity === '') {
            setSrxName(e.target.value)
            startsWith = filtredBigData.filter(item => item.nom.toLowerCase().startsWith(e.target.value.toLowerCase()))
            includes = filtredBigData.filter(item => item.nom.toLowerCase().includes(e.target.value.toLowerCase()))

            if (startsWith.length > 0) {
                filtredData = startsWith
            } else if (startsWith.length === 0 && includes.length > 0) {
                filtredData = includes
            } else {
                filtredData = []
            }

        } else if (e.target.value !== '' && slctCity !== '') {
            setSrxName(e.target.value)
            filtredData = filtredBigData.filter(item => item.villeId === slctCity)

            startsWith = filtredData.filter(item => item.nom.toLowerCase().startsWith(e.target.value.toLowerCase()))
            includes = filtredData.filter(item => item.nom.toLowerCase().includes(e.target.value.toLowerCase()))

            if (startsWith.length > 0) {
                filtredData = startsWith
            } else if (startsWith.length === 0 && includes.length > 0) {
                filtredData = includes
            } else {
                filtredData = []
            }
        } else if (e.target.value === '' && slctCity !== '') {
            setSrxName('')
            filtredData = filtredBigData.filter(item => item.villeId === slctCity)
        } else {
            setSrxName('')
            filtredData = data.filter(item => item.categ === activeTab)
        }
        setFiltredData(filtredData)
    }


    const handelSelect = e => {

        let selectedCity
        let startsWith
        let includes

        if (e.target.value !== '' && srxName === '') {
            selectedCity = data.filter(item => item.villeId === e.target.value && item.categ === activeTab)
            setSlctCity(e.target.value)

        } else if (e.target.value !== '' && srxName !== '') {

            startsWith = filtredBigData.filter(item => item.nom.toLowerCase().startsWith(srxName.toLowerCase()))
            includes = filtredBigData.filter(item => item.nom.toLowerCase().includes(srxName.toLowerCase()))

            if (startsWith.length > 0) {
                selectedCity = startsWith
            } else if (startsWith.length === 0 && includes.length > 0) {
                selectedCity = includes
            } else {
                selectedCity = []
            }

            selectedCity = selectedCity.filter(item => item.villeId === e.target.value)

            setSlctCity(e.target.value)

        } else if (e.target.value === '' && srxName !== '') {
            selectedCity = data.filter(item => item.villeId === e.target.value)

            startsWith = filtredBigData.filter(item => item.nom.toLowerCase().startsWith(srxName.toLowerCase()))
            includes = filtredBigData.filter(item => item.nom.toLowerCase().includes(srxName.toLowerCase()))

            if (startsWith.length > 0) {
                selectedCity = startsWith
            } else if (startsWith.length === 0 && includes.length > 0) {
                selectedCity = includes
            } else {
                selectedCity = []
            }
            setSlctCity('')

        } else {
            selectedCity = data.filter(item => item.categ === activeTab)
            setSlctCity('')
        }
        setFiltredData(selectedCity)
    }


    return (
        <div dir='rtl' className='container-fluid-content'>

            <Row sm="12">
                <div className="iq-card">
                    <div className="iq-card-header d-flex justify-content-between">
                        <div className="iq-header-title">
                            <h4 className="card-title">
                                {/* <Skeleton count={5} /> */}
                                الجمعيات والمنظمات
                            </h4>
                        </div>
                        <Button color='primary' style={{ marginRight: '6px' }} onClick={() => setModal(true)}> <Plus size={15} /> أضف جمعية أو منظمة</Button>
                    </div>
                </div>
            </Row >

            <Row className='d-flex justify-content-center mb-4'>
                <div className="cardsCat d-flex justify-content-center">
                    <div className="cardCat green" onClick={() => setActiveTab('الجمعيات')}>
                        <p className="tip">
                            <img src={Association} alt='' className='imgg' style={{ borderRadius: 0, boxShadow: 'none' }} />
                        </p>
                        <p className="second-text h3">الجمعيات</p>
                    </div>
                    <div className="cardCat red" onClick={() => setActiveTab('المنظمات')}>
                        <p className="tip">
                            <img src={Enteprise} alt='' className='imgg' style={{ borderRadius: 0, boxShadow: 'none' }} />
                        </p>
                        <p className="second-text h5">المنظمات</p>
                    </div>
                </div>

            </Row>
            <Row>
                <Col sm="12">
                    <div className="iq-card">
                        <div className="iq-card-header ">
                            <Row >
                                <Col md="4">
                                    <div className="iq-header-title mt-3">
                                        <h4 className="card-title">محرك البحث</h4>
                                    </div>
                                </Col>
                                <Col md="4">
                                    <FormSelect
                                        className="form-select my-2"
                                        id="exampleFormControlSelect1"
                                        onChange={handelSelect}
                                    >
                                        <option value={''}>{'اختر المدينة'}</option>
                                        {citys.map(item => <option value={item.salt}>{item.nom}</option>)}
                                    </FormSelect>
                                </Col>
                                <Col md="4">
                                    <FormControl
                                        className="form-control my-2"
                                        placeholder="بحث بالإسم ..."
                                        onChange={handelFilter}
                                        name="select"
                                    />
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Col>
            </Row>
            {filtredBigData.length > 0 ? <>
                <CardAssociation allData={filtredBigData} />
            </> : <Fragment >
                <Row className='my-5' >
                    <Col md={2} >
                        <Skeleton height={300} />
                    </Col>
                    <Col md={2} >
                        <Skeleton height={300} />
                    </Col>
                    <Col md={2} >
                        <Skeleton height={300} />
                    </Col>
                    <Col md={2} >
                        <Skeleton height={300} />
                    </Col>
                    <Col md={2} >
                        <Skeleton height={300} />
                    </Col>
                    <Col md={2} >
                        <Skeleton height={300} />
                    </Col>
                </Row>
            </Fragment>}
            <Modal isOpen={modal} toggle={() => setModal(false)} className='' size='xl' backdrop="static" centered>
                <ModalHeader toggle={() => setModal(!modal)} className='text-dark'>
                </ModalHeader>
                <Form onSubmit={handelSubmit}>
                    <ModalBody className='bg-white' dir='rtl'>

                        {steps === 1 && <Row>
                            <Col md="6">
                                <FormGroup>
                                    <Label for="nom">إسم الجمعية أو المنظمة</Label>
                                    <Input type='text' id='nom' onChange={handelChange} />
                                    {titleValid === false ? <span className='text-danger fw-bolder mb-1' > المرجو إدخال إسم الجمعية أو المنظمة </span> : ''}
                                </FormGroup>
                            </Col>
                            <Col md="6">
                                <FormGroup>
                                    <Label for="categ"> التصنيف</Label>
                                    <Input type='select' id='categ' onChange={handelChange} >
                                        <option selected value={'-'}></option>
                                        {cat.map(item => <option value={JSON.stringify(item)}>{item.nom}</option>)}
                                    </Input>
                                </FormGroup>
                            </Col>
                        </Row>}
                        {steps === 2 && <Row className=''>
                            <Label>
                                شعار الجمعية أو المنظمة  : (300 * 300)
                            </Label>
                            <Col md="6" className='d-flex justify-content-center'>
                                <Button tag={Label} className={`m-0 p-0 w-75 `} size='md' color=''>
                                    <img src={avatar} alt='' role='button' width={'auto'} height={'180px'} />
                                    <Input type='file' onChange={onChange} hidden accept='image/*' />
                                </Button>
                            </Col>
                            <Col md="6">
                                <Row>
                                    <Col md="12">
                                        <FormGroup>
                                            <Label for="phone">الهاتف</Label>
                                            <Input type='text' id='phone' onChange={handelChange} />
                                        </FormGroup>
                                    </Col>
                                    <Col md="12">
                                        <FormGroup>
                                            <Label for="email">البريد الإلكتروني</Label>
                                            <Input type='text' id='email' onChange={handelChange} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md="6">
                                <FormGroup>
                                    <Label for="ville"> المدينة</Label>
                                    <Input type='select' id='ville' onChange={handelChange} >
                                        <option selected value={'-'}></option>
                                        {citys.map(item => <option value={JSON.stringify(item)}>{item.nom}</option>)}
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col md="6">
                                <FormGroup>
                                    <Label for="adresse">العنوان</Label>
                                    <Input type='text' id='adresse' onChange={handelChange} />
                                </FormGroup>
                            </Col>
                            <Col md="12">
                                <FormGroup>
                                    <Label for="mapLink">رابط الخريطة</Label>
                                    <Input type='text' id='mapLink' onChange={handelChange} />
                                </FormGroup>
                            </Col>

                        </Row>}
                        {steps === 3 && <Row className=''>

                            <Col md="6">
                                <FormGroup>
                                    <Label for="facebook">الفيسبوك</Label>
                                    <Input type='text' id='facebook' onChange={handelChange} />
                                </FormGroup>
                            </Col>
                            <Col md="6">
                                <FormGroup>
                                    <Label for="twitter">تويتر</Label>
                                    <Input type='text' id='twitter' onChange={handelChange} />
                                </FormGroup>
                            </Col>
                            <Col md="6">
                                <FormGroup>
                                    <Label for="linkidIn">لينكد إن</Label>
                                    <Input type='text' id='linkidIn' onChange={handelChange} />
                                </FormGroup>
                            </Col>
                            <Col md="6">
                                <FormGroup>
                                    <Label for="instagram">انستغرام</Label>
                                    <Input type='text' id='instagram' onChange={handelChange} />
                                </FormGroup>
                            </Col>
                        </Row>}{" "}
                        <div className="text-center">
                            <span className={`step ${steps > 1 ? "finish" : ""} ${steps === 1 ? "active" : ""}`} />
                            <span className={`step ${steps > 2 ? "finish" : ""} ${steps === 2 ? "active" : ""}`} />
                            <span className={`step ${steps > 3 ? "finish" : ""} ${steps === 3 ? "active" : ""}`} />{" "}
                        </div>
                    </ModalBody>
                    <ModalFooter className='d-flex justify-content-between bg-white '>
                        {steps < 3 ? <Button color='primary' type="button" onClick={handelNext}>
                            التالي
                        </Button> : ''}


                        {steps === 3 ? <Button color='primary'>أضف</Button> : ''}
                    </ModalFooter>
                </Form>
            </Modal>
        </div>
    )
}
