import React, { Fragment, useState } from 'react';
import { Card, CardHeader, Col } from 'reactstrap';
import CreatedByme from './CreatedByme';
import { FormControl, FormSelect } from 'react-bootstrap';

const ListOfTask = ({ allData, cat, citys }) => {
    const [data, setData] = useState(allData)
    const [srxName, setSrxName] = useState('')
    const [slctCity, setSlctCity] = useState('')
    const handelFilter = e => {
        let filtredData
        let startsWith
        let includes

        if (e.target.value !== '' && slctCity === '') {
            setSrxName(e.target.value)
            startsWith = allData.filter(item => item.nom.toLowerCase().startsWith(e.target.value.toLowerCase()))
            includes = allData.filter(item => item.nom.toLowerCase().includes(e.target.value.toLowerCase()))

            if (startsWith.length > 0) {
                filtredData = startsWith
            } else if (startsWith.length === 0 && includes.length > 0) {
                filtredData = includes
            } else {
                filtredData = []
            }

        } else if (e.target.value !== '' && slctCity !== '') {
            setSrxName(e.target.value)
            filtredData = allData.filter(item => item.villeId === slctCity)

            startsWith = filtredData.filter(item => item.nom.toLowerCase().startsWith(e.target.value.toLowerCase()))
            includes = filtredData.filter(item => item.nom.toLowerCase().includes(e.target.value.toLowerCase()))

            if (startsWith.length > 0) {
                filtredData = startsWith
            } else if (startsWith.length === 0 && includes.length > 0) {
                filtredData = includes
            } else {
                filtredData = []
            }
        } else if (e.target.value === '' && slctCity !== '') {
            setSrxName('')
            filtredData = allData.filter(item => item.villeId === slctCity)
        } else {
            setSrxName('')
            filtredData = allData
        }
        setData(filtredData)
    }

    const handelSelect = e => {

        let selectedCity
        let startsWith
        let includes

        if (e.target.value !== '' && srxName === '') {
            selectedCity = allData.filter(item => item.villeId === e.target.value)
            setSlctCity(e.target.value)

        } else if (e.target.value !== '' && srxName !== '') {

            startsWith = allData.filter(item => item.nom.toLowerCase().startsWith(srxName.toLowerCase()))
            includes = allData.filter(item => item.nom.toLowerCase().includes(srxName.toLowerCase()))

            if (startsWith.length > 0) {
                selectedCity = startsWith
            } else if (startsWith.length === 0 && includes.length > 0) {
                selectedCity = includes
            } else {
                selectedCity = []
            }

            selectedCity = selectedCity.filter(item => item.villeId === e.target.value)

            setSlctCity(e.target.value)

        } else if (e.target.value === '' && srxName !== '') {
            selectedCity = data.filter(item => item.villeId === e.target.value)

            startsWith = allData.filter(item => item.nom.toLowerCase().startsWith(srxName.toLowerCase()))
            includes = allData.filter(item => item.nom.toLowerCase().includes(srxName.toLowerCase()))

            if (startsWith.length > 0) {
                selectedCity = startsWith
            } else if (startsWith.length === 0 && includes.length > 0) {
                selectedCity = includes
            } else {
                selectedCity = []
            }
            setSlctCity('')

        } else {
            selectedCity = allData
            setSlctCity('')
        }
        setData(selectedCity)
    }

    return (
        <Fragment>
            <Card className="mb-0" style={{ background: '#fff !important' }}>
                <CardHeader className="d-flex justify-content-between align-items-center bg-dark" style={{ background: '#fff !important' }}>
                    <h6 className='mb-0 ' >{'محرك البحث'}</h6>
                    <Col md="4">
                        <FormSelect
                            className="form-select my-2"
                            id="exampleFormControlSelect1"
                            onChange={handelSelect}
                        >
                            <option value={''}>{'اختر المدينة'}</option>
                            {citys.map(item => <option value={item.salt}>{item.nom}</option>)}
                        </FormSelect>
                    </Col>
                    <Col md="4">
                        <FormControl
                            className="form-control my-2"
                            placeholder="بحث بالإسم ..."
                            onChange={handelFilter}
                            name="select"
                        />
                    </Col>
                </CardHeader>
                <CreatedByme allData={data} />
            </Card>
        </Fragment >
    );
};

export default ListOfTask;